import styles from "@src/components/Party.module.css";

type Params = {
  // If true, apply neon blur effect.
  glow?: boolean;
};

function Party(params: Params): JSX.Element {
  const { glow } = params;

  // On Safari, enabling the glow effect makes the image very pixelated.
  // https://stackoverflow.com/a/23522755
  const isSafari = /^((?!chrome|android).)*safari/i.test(
    globalThis.navigator?.userAgent
  );

  return (
    // This Font Software is licensed under the SIL Open Font
    // License, Version 1.1: http://scripts.sil.org/OFL
    //
    // Adapted from Noto Emoji <3
    // https://github.com/googlefonts/noto-emoji
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -5 40 40"
      className={[styles.party, glow && !isSafari && styles.glow]
        .filter((x) => x)
        .join(" ")}
    >
      <defs>
        <filter
          id="glow"
          filterUnits="userSpaceOnUse"
          x="-20%"
          y="-20%"
          width="140%"
          height="140%"
        >
          <feGaussianBlur in="SourceGraphic" stdDeviation="2" result="blur" />
          <feMerge>
            <feMergeNode in="SourceGraphic" />
            <feMergeNode in="blur" />
          </feMerge>
        </filter>
      </defs>
      <path
        d="M6.203 30.906q-0.719 0-1.164-0.406t-0.445-1.125q0-0.422 0.164-1.023t0.555-1.508l5.922-14.047 1.141 0.469-5.906 14.047q-0.281 0.672-0.422 1.102t-0.141 0.695q0 0.203 0.094 0.375t0.422 0.172q0.359 0 1.078-0.266t1.5-0.578l12.297-5.031 1.656 0.688-13.5 5.516q-1.234 0.5-1.984 0.711t-1.266 0.211z"
        className={styles.red}
      />
      <path
        d="M11.063 28.625q-1.438-0.656-2.508-1.664t-1.617-2.258l0.672-1.453q0.594 1.609 1.914 2.844t3.102 1.844z"
        className={styles.red}
      />
      <path
        d="M15.391 26.875q-1.516-0.359-2.875-1.273t-2.328-2.203-1.344-2.742l0.766-1.688q0.281 1.844 1.367 3.375t2.727 2.516 3.547 1.172z"
        className={styles.red}
      />

      <path
        d="M20.609 24.906q-2.016 0-3.797-0.75t-3.141-2.070-2.141-3.039-0.781-3.656q0-2.469 1.25-3.828t3.484-1.359q1.859 0 3.602 0.859t3.148 2.328 2.227 3.297 0.82 3.75q0 2.172-1.195 3.32t-3.477 1.148z"
        className={[styles.red, styles.outer].join(" ")}
      />
      <path
        d="M20.609 23.656q1.766 0 2.594-0.781t0.828-2.438q0-1.672-0.727-3.266t-1.953-2.891-2.75-2.063-3.102-0.766q-1.703 0-2.594 1t-0.891 2.938q0 1.688 0.688 3.18t1.883 2.633 2.742 1.797 3.281 0.656z"
        className={[styles.red, styles.inner].join(" ")}
      />

      <path
        d="M7.109 6.531q-0.625-0.234-1.164-0.555t-1.039-0.727l0.906-0.938q0.359 0.234 0.734 0.461t0.844 0.461z"
        className={styles.orange}
      />
      <path
        d="M9.641 3l-1.375-0.453q0.25-0.938 0.141-1.781l1.406-0.219q0.109 1.219-0.172 2.453z"
        className={styles.pink}
      />
      <path
        d="M11.359 10l-2.141-1.109 0.844-1.234 1.828 1.141z"
        className={styles.green}
      />

      <path
        d="M23.797 3.656l-0.438-2.156 1.359-0.281 0.391 2.188z"
        className={styles.green}
      />
      <path
        d="M28.078 1.063l-1-1.016q0.344-0.375 0.57-0.789t0.32-0.852l1.313 0.219q-0.141 0.672-0.445 1.266t-0.758 1.172z"
        className={styles.yellow}
      />

      <path
        d="M28.188 17.516l-1.188-1.375 1.125-0.969 1.188 1.344z"
        className={styles.blue}
      />
      <path
        d="M30.313 13.203l-0.594-1.875 1.281-0.406 0.547 1.938z"
        className={styles.pink}
      />

      <path
        d="M29.422 28.031l-1.406-0.234q-0.016-0.375-0.148-0.781t-0.352-0.797l1.094-0.875q0.359 0.578 0.57 1.258t0.242 1.43z"
        className={styles.pink}
      />
      <path
        d="M35.75 24.922l-1.859-0.125-0.047-1.391 2.109 0.141z"
        className={styles.orange}
      />
      <path
        d="M34.828 29.844l-1.609-1.516 0.969-1 1.609 1.609z"
        className={styles.blue}
      />

      <path
        d="M15.188 17.656l-0.891-0.875q0.922-0.969 1.391-1.938t0.469-1.938q0-0.484-0.094-1.109t-0.281-1.281q-0.406-1.391-0.406-2.422 0-1.094 0.523-2.016t1.398-1.484 1.891-0.563q1.219 0 2.031 0.805t0.813 2.023q0 1.234-0.883 1.977t-2.352 0.742q-1.359 0-2.469-0.656t-1.758-1.781-0.648-2.516q0-2.234 1.625-3.711t4.453-1.648l0.078 1.25q-2.313 0.141-3.609 1.25t-1.297 2.859q0 1.047 0.469 1.883t1.289 1.328 1.867 0.492q0.922 0 1.453-0.383t0.531-1.086q0-0.688-0.445-1.133t-1.148-0.445q-1 0-1.781 0.852t-0.781 1.961q0 0.344 0.086 0.836t0.289 1.305q0.188 0.75 0.297 1.438t0.109 1.234q0 2.406-2.219 4.75z"
        className={styles.blue}
      />
      <path
        d="M18.594 18.141q0-1.891 1.031-2.961t3.359-1.695q1.375-0.359 1.797-0.648t0.422-0.867q0-0.281-0.078-0.531t-0.344-0.734q-0.625-1.141-0.625-2.063 0-1.219 0.758-1.969t1.945-0.75q0.688 0 1.383 0.289t1.664 1.008q0.875 0.641 1.422 0.883t1.047 0.242q0.875 0 1.266-0.625t0.391-1.906q0-1.25-0.414-2.391t-1.367-2.453l1-0.75q1.063 1.469 1.547 2.82t0.484 2.773q0 1.813-0.758 2.797t-2.148 0.984q-0.719 0-1.453-0.313t-1.75-1.047q-0.859-0.641-1.352-0.852t-0.961-0.211q-0.688 0-1.070 0.367t-0.383 1.102q0 0.297 0.102 0.617t0.383 0.852q0.313 0.578 0.438 0.992t0.125 0.867q0 1.031-0.695 1.648t-2.461 1.070q-1.984 0.516-2.719 1.242t-0.734 2.211h-1.25z"
        className={styles.purple}
      />
      <path
        d="M29.391 23.094q-0.797 0-1.516-0.375t-1.719-1.234q-0.766-0.688-1.18-0.914t-0.82-0.227q-0.25 0-0.523 0.086t-0.773 0.352q-0.938 0.484-1.695 0.672t-1.602 0.188q-0.391 0-0.844-0.047t-0.969-0.156l0.25-1.234q0.922 0.188 1.625 0.188 0.688 0 1.289-0.172t1.398-0.563q0.625-0.313 1.039-0.438t0.805-0.125q0.625 0 1.258 0.328t1.555 1.125q0.875 0.75 1.391 1.023t1.031 0.273q0.734 0 1.094-0.539t0.734-2.18q0.594-2.547 1.68-3.648t3.133-1.102v1.25q-1.078 0-1.75 0.336t-1.086 1.156-0.758 2.289q-0.484 2.078-1.172 2.883t-1.875 0.805z"
        className={styles.green}
      />
    </svg>
  );
}

export default Party;
